<template>
  <div class="full-page overflow-hidden position-relative app-content">
    <div class="full-panel d-flex flex-column justify-content-center h-100">
      <div>
        <div class="form-group position-relative">
          <input id="email" type="email" class="login-input" placeholder="輸入註冊 email" v-model="email" />
        </div>
        <button id="login" class="btn btn-primary btn-block" @click="sendEmail">
          寄送密碼提示
        </button>
        <button class="btn btn-primary btn-block" @click="back">
          上一頁
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "forgetPassword",
  data() {
    return {
      email: ""
    };
  },
  methods: {
    sendEmail() {},
    back() {
      this.$router.back();
    }
  }
};
</script>

<style scoped></style>
