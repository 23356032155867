<template>
  <div class="p-3 position-relative" id="#top" @scroll="Onscroll">
    <div class="shadow-sm rounded p-3 my-4" id="mobile-test">
      <h1 class="h5 my-2">
        <div class="text-center my-2 pb-2">手機硬體測試</div>
      </h1>
      <div class="container">
        <div class="row mb-2">
          <div class="col-6">
            螢幕尺寸
          </div>
          <div class="col-6" :class="setStatus(screen)">
            {{ screen }}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-6">
            GPS
          </div>
          <div class="col-6" :class="setStatus(gps)">
            {{ gps }}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-6">
            加速儀
          </div>
          <div class="col-6" v-text="DeviceMotionEvent" :class="setStatus(DeviceMotionEvent)"></div>
        </div>
        <div class="row mb-4">
          <div class="col-6">
            陀螺儀
          </div>
          <div class="col-6" v-text="DeviceOrientationEvent" :class="setStatus(DeviceOrientationEvent)"></div>
        </div>
        <div class="pb-2">
          <button class="btn btn-primary btn-block" @click="doTest">點擊測試</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mobileTestOnly",
  data() {
    return {
      screen: "",
      gps: "",
      DeviceMotionEvent: "",
      DeviceOrientationEvent: "",
      goTop: false,
      isCopy: false
    };
  },
  computed: {},
  mounted() {
    window.addEventListener("scroll", this.Onscroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.Onscroll);
  },
  methods: {
    Onscroll() {
      this.goTop = window.pageYOffset !== 0 ? true : false;
      if (!this.isCopy) {
        return;
      }
      this.isCopy = false;
    },
    checkDeviceOrientation() {
      if (typeof DeviceOrientationEvent.requestPermission === "function") {
        DeviceOrientationEvent.requestPermission()
          .then(permissionState => {
            if (permissionState == "granted") {
              this.DeviceMotionEvent = "最佳體驗";
            } else {
              this.DeviceMotionEvent = "不支援";
            }
          })
          .catch(() => {
            this.DeviceOrientationEvent = "不支援";
          });
      }
      if (window.DeviceOrientationEvent) this.DeviceOrientationEvent = "最佳體驗";
      else {
        this.DeviceOrientationEvent = "不支援";
      }
    },
    getDeviceMotionEvent() {
      if (typeof DeviceMotionEvent.requestPermission === "function") {
        DeviceMotionEvent.requestPermission()
          .then(permissionState => {
            if (permissionState == "granted") {
              this.DeviceMotionEvent = "最佳體驗";
            } else {
              this.DeviceMotionEvent = "不支援";
            }
          })
          .catch(() => {
            this.DeviceMotionEvent = "不支援";
          });
      }
      if (window.DeviceOrientationEvent) this.DeviceMotionEvent = "最佳體驗";
      else {
        this.DeviceMotionEvent = "不支援";
      }
    },
    doTest() {
      let windowWidth = (this.windowHeight = window.innerHeight);
      // if (windowWidth < 520) {
      if (windowWidth < 600) {
        //排除Iphone5&&SE
        this.screen = "體驗稍差";
      } else {
        this.screen = "最佳體驗";
      }

      if (navigator.geolocation) {
        this.gps = "最佳體驗";
      } else {
        this.gps = "不支援";
      }
      this.getDeviceMotionEvent();
      this.checkDeviceOrientation();
    },
    setStatus(words) {
      if (words == "體驗稍差") {
        return "text-warning";
      }
      if (words == "最佳體驗") {
        return "text-success";
      }
      if (words == "不支援") {
        return "text-danger";
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
