<template>
  <div></div>
</template>

<script>
import UserFromVuex from "../Mixins/UserFromVuex";

export default {
  name: "logout",
  mixins: [UserFromVuex],
  mounted() {
    this.logout();
  }
};
</script>

<style scoped></style>
