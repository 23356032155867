import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["socket"]),
    ...mapGetters("User", ["user", "token"]),
    ...mapGetters("Team", ["team_payload"])
  },
  methods: {
    ...mapActions(["sendMessage", "disableFirstConnect"]),
    ...mapActions("User", ["setFd", "SyncUserStatus", "flagSyncTimer", "sendFlagSync", "setOnline", "setOffline"]),
    offLine() {
      console.log("websocket offline");
    },
    onLine(val) {
      let fd = val?.message?.fd;
      if (fd) {
        // this.setFd(fd)
        // this.sendFlagSync()
        console.log("websocket online");
      } else {
        setTimeout(this.onLine, 100);
      }
    }
  },
  mounted() {
    this.flagSyncTimer();
  },
  watch: {
    socket: {
      deep: true,
      immediate: true,
      handler(val, oldVal) {
        if (val?.isConnected === oldVal?.isConnected) return;
        if (val.isConnected) {
          this.onLine(val);
        } else {
          this.offLine();
        }
      }
    }
  }
};
