<template>
  <div class="full-page overflow-hidden position-relative app-content">
    <div class="full-panel d-flex flex-column justify-content-center h-100">
      <div v-if="selectType">
        <button @click="doSelect" class="btn btn-default btn-block" role="button">
          登入
        </button>
        <div class="pt-3 pb-1 text-center small-font">
          還沒有帳號？
        </div>
        <a role="button" class="btn btn-primary btn-block text-white" @click="$router.replace('signup')">註冊</a>
      </div>
      <div v-if="is_login">
        <div class="form-group position-relative">
          <input
            id="email"
            type="email"
            class="login-input-shadow"
            placeholder="帳號(email)"
            v-model="userForm.email"
          />
        </div>
        <div class="form-group position-relative">
          <input
            id="password"
            type="password"
            class="login-input-shadow"
            placeholder="密碼"
            v-model="userForm.password"
          />
        </div>
        <div class="py-1 pb-4 text-right">
          <a class="small-font default-link" @click="$router.replace('forgetPassword')">忘記密碼?</a>
        </div>
        <!--        <div class="form-group">-->
        <!--          <a-->
        <!--            class="btn-sn d-block small-font btn-fb d-flex justify-content-center align-items-center text-white"-->
        <!--            role="button"-->
        <!--          >-->
        <!--            <svg class="btn-icon mr-2">-->
        <!--              `-->
        <!--              <use xlink:href="#icon-fb" class="fill-white"></use>-->
        <!--            </svg>-->
        <!--            <v-facebook-login app-id="250932022689447"></v-facebook-login>-->
        <!--          </a>-->
        <!--        </div>-->
        <!--        <div class="form-group mb-5">-->
        <!--          <a class="btn-sn d-block small-font d-flex justify-content-center align-items-center" role="button">-->
        <!--            <svg class="btn-icon mr-2">-->
        <!--              <use xlink:href="#icon-google" class="fill-google"></use>-->
        <!--            </svg>-->
        <!--            使用 Google 帳號登入-->
        <!--          </a>-->
        <!--        </div>-->
        <div class="form-group">
          <button id="login" class="btn btn-primary btn-block" :disabled="inputEmpty" @click="login">
            登入
          </button>
        </div>
        <a role="button" class="btn btn-primary btn-block text-white" @click="$router.replace('signup')">註冊</a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import ModalMixin from "../Mixins/ModalMixin";
import UserFromVuex from "@/Mixins/UserFromVuex";
import TeamFromVuex from "@/Mixins/TeamFromVuex";
// import VFacebookLogin from "vue-facebook-login-component";
export default {
  name: "Login",
  mixins: [ModalMixin, UserFromVuex, TeamFromVuex],
  components: {
    // VFacebookLogin
  },
  data() {
    return {
      selectType: true,
      is_login: false,
      redirect: null,
      userForm: {
        email: "",
        password: ""
      }
    };
  },
  computed: {
    inputEmpty() {
      return this.userForm.email.length == 0 || this.userForm.password.length == 0;
    }
  },
  beforeCreate() {},
  mounted() {
    if (this.user?.id && this.team?.id && this.character) {
      if (this.latestPath) {
        this.$router.replace(this.latestPath);
        return;
      }
      this.$router.replace("homeMap");
      return;
    }

    if (this.user?.id && this.team?.id) {
      this.$router.replace("teamCreate");
      return;
    }
  },
  methods: {
    ...mapActions("User", ["doLogin"]),
    handleSdkInit({ FB, scope }) {
      this.FB = FB;
      this.scope = scope;
    },
    doSelect: function() {
      this.selectType = false;
      this.is_login = true;
    },
    login: async function() {
      let result = await this.doLogin(this.userForm);
      if(this.team_payload?.router){
        this.$router.replace(this.team_payload.router);
        return
      }
      if (result) {
        if (this.redirect) {
          this.$router.replace(this.redirect);
          return;
        }
        this.$router.replace("/gameIntro");

        return;
      }
      this.showModal("帳號密碼錯誤 或者帳號不存在");
    }
  }
};
</script>
<style scoped>
.login-input-shadow {
  border: none;
  background: none;
  padding: 10px 30px;
  width: 100%;
  border-radius: 30px;
  transition: 0.3s;
  -webkit-appearance: none;
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16);
}
</style>
