<template>

  <div class="full-page overflow-hidden position-relative app-content">
    <div v-if="lock" class="loader loader-default is-active" data-text data-blink> </div>
    <!-- <svg class="position-absolute back-icon btn-icon" v-if="step2" @click="back">
      <use xlink:href="#arrow-left" class="fill-primary"></use>
    </svg> -->
    <div class="full-panel d-flex flex-column justify-content-between h-100">
      <h1 class="full-page-title">帳戶資訊</h1>
      <div v-if="step1">
        <div class="form-group position-relative">
          <input
            type="text"
            v-model="user.nickname"
            class="login-input-shadow"
            placeholder="遊戲內使用的暱稱"
            maxlength="10"
          />
        </div>
        <div class="form-group position-relative">
          <input
            type="email"
            @blur="checkEmail"
            @change="clearEmailAlert"
            v-model="user.email"
            class="login-input-shadow"
            placeholder="自定帳號(email)"
            :class="{ 'text-danger': email_danger }"
          />
        </div>
        <div class="form-group position-relative">
          <input type="password" v-model="user.password" class="login-input-shadow" placeholder="自定密碼(8~12字元)" />
        </div>
        <div class="form-group position-relative">
          <input type="password" v-model="password_check" class="login-input-shadow" placeholder="確認密碼" />
        </div>
      </div>
      <div v-if="step2">
        <div class="form-row">
          <div class="form-group col-12">
            <select class="login-input" name="year" v-model="year">
              <option :value="0">出生年份</option>
              <option :value="row" v-for="row in year_list" :key="row" v-text="row"></option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-6">
            <select class="login-input" name="month" v-model="month">
              <option :value="0">出生月份</option>
              <option :value="row" v-for="row in month_list" :key="row" v-text="row"></option>
            </select>
          </div>
          <div class="form-group col-6">
            <select class="login-input" name="date" v-model="date">
              <option :value="0">出生日期</option>
              <option :value="row" v-for="row in date_list" :key="row" v-text="row"></option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12">
            <select class="login-input" v-model="user.gender">
              <option value="" style="display: none">生理性別</option>
              <option value="M">男</option>
              <option value="F">女</option>
              <!-- <option value="0">其他</option> -->
            </select>
          </div>
        </div>
      </div>
      <div>
        <div v-if="step1" class="form-group position-relative">
          <button @click="goStep2" class="btn btn-primary btn-block">
            繼續
          </button>
          <button @click="goback" class="btn btn-primary btn-block">
            回登入頁
          </button>
        </div>
        <div class="small-font mb-2" v-if="step2">註冊會員即表示我同意<a class="default-link">網站服務條款</a></div>
        <div v-if="step2" class="form-group position-relative">
          <button @click="doSign" class="btn btn-primary btn-block">
            繼續
          </button>
          <button class="btn btn-primary btn-block" @click="back">
            回註冊頁
          </button>
        </div>
        <!--        <div class="text-center small-font pb-2">或</div>-->
        <!--        <a @click="$router.replace('login')" role="button" class="btn btn-default btn-block">使用社群帳號登入</a>-->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { checkEmail } from "@/utils/Api";
import ModalMixin from "../Mixins/ModalMixin";

export default {
  name: "signUp",
  mixins: [ModalMixin],
  data() {
    return {
      lock:false,
      step1: true,
      step2: false,
      email_exist: false,
      email_danger: false,
      user: {
        nickname: "",
        email: "",
        password: "",
        gender: "",
        birthday: ""
      },
      password_check: null,
      year: 0,
      month: 0,
      date: 0
    };
  },
  computed: {
    isEmail: function() {
      return this.validateEmail(this.user.email);
    },
    year_list: function() {
      let list = [];
      for (let i = 1900; i < 2020; i++) {
        list.push(i);
      }
      return list.reverse();
    },
    month_list: function() {
      let list = [];
      for (let i = 1; i <= 12; i++) {
        list.push(i);
      }
      return list;
    },
    date_list: function() {
      let list = [];
      for (let i = 1; i <= 31; i++) {
        list.push(i);
      }
      return list;
    }
  },
  mounted() {
    this.$recaptchaLoaded();
    this.$recaptchaInstance?.hideBadge();
  },
  methods: {
    ...mapActions("User", ["doSignUp"]),
    clearEmailAlert() {
      this.email_exist = false;
      this.email_danger = false;
    },
    checkEmail: async function() {
      if (!this.isEmail) {
        this.email_danger = true;
        return;
      }
      let response = await checkEmail({ email: this.user.email });
      if (response.status == 200 && response.data.exists === false) {
        this.email_exist = false;
      } else {
        this.email_exist = true;
        this.email_danger = true;
      }
    },
    goback() {
      this.$router.replace("/login");
    },
    goStep2: function() {
      let error = [];
      if (this.user.nickname.length == 0) {
        error.push("暱稱不能為空");
      }
      if (this.user.email.length == 0) {
        error.push("email不能為空");
      }
      if (this.email_exist) {
        error.push("email已經有人使用");
      }
      if ((this.user.password.length < 8) | (this.user.password.length > 12)) {
        error.push("密碼長度必須介於8到12個字元");
      }
      if (!this.isEmail) {
        error.push("email格式錯誤");
      }
      if (this.user.password !== this.password_check) {
        error.push("兩次輸入不一致 請確認密碼");
      }

      if (error.length > 0) {
        alert(error.join("\n"));
        return;
      }
      this.step1 = false;
      this.step2 = true;
    },
    back: function() {
      this.step1 = true;
      this.step2 = false;
    },
    doSign: async function() {
      if(this.lock) {
        alert('請稍等');
        return;
      };

      this.lock = true;
      let error = [];
      if ((this.year === 0) | (this.month === 0) | (this.date === 0)) {
        error.push("請輸入生日");
      }
      if (this.user.gender == 0) {
        error.push("請輸入性別");
      }
      if (error.length > 0) {
        this.showModal(error.join("\n"));
        this.lock = false;
        return;
      }
      this.user.birthday = `${this.year}-${this.month}-${this.date}`;
      let form = Object.freeze(this.user);
      const token = await this.$recaptcha("sighup");
      if (token) {
        // console.log(token)
      } else {
        this.showModal("你是機器人嗎？");
        return;
      }
      try{
        if (await this.doSignUp({ form, token })) {
          // this.showModal("帳號申請完成 將導向輸入遊戲序號畫面");
          this.$router.replace("gameIntro");
          return;
        }
      }catch (e){
        this.lock = false;
        console.log(e)
        return;
      }


      this.showModal("帳號申請失敗");
      this.lock = false;
    },
    validateEmail: function(email) {
      //eslint-disable-next-line
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
  }
};
</script>


<style scoped>
@import '../assets/css/loader-default.css';

.login-input-shadow {
  border: none;
  background: none;
  padding: 10px 30px;
  width: 100%;
  border-radius: 30px;
  transition: 0.3s;
  -webkit-appearance: none;
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16);
}
</style>
