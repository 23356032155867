<template>
  <div class="p-3 position-relative" id="#top" @scroll="Onscroll" @click="isCopy = false">
    <div class="copyDiv" v-if="isCopy">已複製</div>
    <div class="moveTop shadow-sm" v-show="goTop">
      <a
        role="button"
        v-scroll-to="{
          el: '#app',
          duration: 300,
          force: true,
          cancelable: true,
          x: false,
          y: true
        }"
      >
        <div class="icon-up-size">
          <img src="/img/icon-up.png" />
        </div>
      </a>
    </div>
    <div class="shadow-sm rounded p-3">
      <h1 class="h5 mb-2 text-center">
        <div class="main-title">《那些年，我們一起的戀愛滋味》</div>
        <div class=" my-2">行前說明</div>
      </h1>
      <p class="container pt-3">
        《那些年，我們一起的戀愛滋味》城市闖關遊戲將全程使用手機遊玩、體驗，我們將會使用到你手機裡的所有硬體（包含相機、陀螺儀、加速儀、GPS⋯等等），請將此頁網址轉發給每位參與的同學
        <span class="text-danger">測試手機功能</span>，確保每個人都準備就緒才能出發喔！
      </p>
      <div class="container my-3 d-flex justify-content-around">
        <a class="icon-box" href="#" @click.prevent="copyLink">
          <img class="share-icon" src="/img/icons/copy-link.svg" alt="" />
          <small>複製連結</small>
        </a>
        <a
          class="icon-box"
          target="_blank"
          href="https://social-plugins.line.me/lineit/share?url=https://theyears.badideasstudio.com/checklist?openExternalBrowser=1"
        >
          <img class="share-icon" src="/img/icons/line.svg" alt="" />
          <small>Line分享</small>
        </a>
        <a
          class="icon-box"
          target="_blank"
          href="fb-messenger://share?link=https%3A%2F%2Ftheyears.badideasstudio.com%2Fchecklist"
        >
          <img class="share-icon" src="/img/icons/FB-message.svg" alt="" />
          <small>FB訊息分享</small>
        </a>
      </div>
    </div>
    <div class="leadToInfo shadow-sm rounded p-3 my-4">
      <div class="d-flex justify-content-around flex-column">
        <a
          class=" info-link"
          v-scroll-to="{
            el: '#mobile-test',
            duration: 300,
            force: true,
            cancelable: true,
            x: false,
            y: true
          }"
        >
          <svg class="btn-icon">
            <use xlink:href="#arrow-right" class="fill-primary"></use>
          </svg>
          手機硬體測試</a
        >
        <a
          v-scroll-to="{
            el: '#before',
            duration: 300,
            force: true,
            cancelable: true,
            x: false,
            y: true
          }"
          ><svg class="btn-icon">
            <use xlink:href="#arrow-right" class="fill-primary"></use></svg
          >遊玩提醒</a
        >
        <a
          v-scroll-to="{
            el: '#the-day',
            duration: 300,
            force: true,
            cancelable: true,
            x: false,
            y: true
          }"
          ><svg class="btn-icon">
            <use xlink:href="#arrow-right" class="fill-primary"></use></svg
          >當天遊戲流程</a
        >
        <a
          v-scroll-to="{
            el: '#notice',
            duration: 300,
            force: true,
            cancelable: true,
            x: false,
            y: true
          }"
          ><svg class="btn-icon">
            <use xlink:href="#arrow-right" class="fill-primary"></use></svg
          >注意事項</a
        >
        <a
          v-scroll-to="{
            el: '#install',
            duration: 300,
            force: true,
            cancelable: true,
            x: false,
            y: true
          }"
          ><svg class="btn-icon">
            <use xlink:href="#arrow-right" class="fill-primary"></use></svg
          >安裝遊戲
        </a>
      </div>
    </div>

    <div class="shadow-sm rounded p-3 my-4" id="mobile-test">
      <h1 class="h5 my-2">
        <div class="text-center my-2 pb-2">手機硬體測試</div>
      </h1>
      <div class="container">
        <div class="row mb-2">
          <div class="col-6">
            螢幕尺寸
          </div>
          <div class="col-6" :class="setStatus(screen)">
            {{ screen }}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-6">
            GPS
          </div>
          <div class="col-6" :class="setStatus(gps)">
            {{ gps }}
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-6">
            加速儀
          </div>
          <div class="col-6" v-text="DeviceMotionEvent" :class="setStatus(DeviceMotionEvent)"></div>
        </div>
        <div class="row mb-4">
          <div class="col-6">
            陀螺儀
          </div>
          <div class="col-6" v-text="DeviceOrientationEvent" :class="setStatus(DeviceOrientationEvent)"></div>
        </div>
        <div class="pb-2">
          <button class="btn btn-primary btn-block" @click="doTest">點擊測試</button>
        </div>
      </div>
    </div>
    <div class="shadow-sm rounded p-3 my-4" id="before">
      <h1 class="h5 my-2">
        <div class="text-center my-2 pb-2">遊玩提醒</div>
      </h1>
      <div class="container">
        <img class="before-play mb-3" src="/img/before-play.jpg" alt="" />
        <ol class="pl-1">
          <li>
            <span class="text-danger">遊戲時長約4－6小時，網路用量約為2GB，必須用行動網路不能使用wifi熱點</span
            >，因全程皆需使用手機遊玩，請確保每個人都有足夠的網路流量，並請記得帶上行動電源，保持手機電量充足。
          </li>
          <li>
            遊玩地點多半在<span class="text-danger">戶外體驗</span>，
            不受地點營業時間限制，想白天或晚上玩都OK（建議白天遊玩光線充足、體驗較佳），
            由於遊戲中會將手機當成遊戲手把操作，建議遊玩前安裝手機殼，以防手機摔落毀損。
          </li>
          <li>
            戶外活動部分多，且有許多<span class="text-danger">動態互動玩法</span>，
            請穿上輕便服裝及好走的鞋子，並注意交通安全！
          </li>
          <li>
            <span class="text-danger">全程約走一萬步</span>，請攜帶<span class="text-danger">口罩</span>及<span
              class="text-danger"
              >悠遊卡</span
            >， 關卡間移動以<span class="text-danger">步行搭配公車、Ubike、捷運</span
            >，以免體力過度消耗、影響遊戲體驗；千萬不要騎車或開車，光找停車位就會相當破壞體驗喔！
          </li>
        </ol>
      </div>
    </div>

    <div class="shadow-sm rounded p-3 my-4" id="the-day">
      <h1 class="h5 text-center my-2">
        <div class="my-2 pb-2">當天遊戲流程</div>
      </h1>
      <div class="">
        <div class="d-flex ">
          <span>
            Step1、
          </span>
          <span>
            <a class="text-danger" href="#addGame">事先將遊戲安裝至手機桌面，並且自行選擇集合地點</a>
            （台北車站、228公園、中正紀念堂、西門町、南陽街、中山堂、北一女等）
          </span>
        </div>
        <div class="d-flex ">
          <span>
            Step2、
          </span>
          <span>
            <span class="text-danger">遊戲當天</span>，人員到齊<span class="text-danger">後</span
            >，註冊會員並登錄票券序號
          </span>
        </div>
        <div class="d-flex ">
          <span>
            Step3、
          </span>
          <span>
            一個隊伍為2至6人，若玩家人數多於6位，須進行分隊
          </span>
        </div>
        <div class="d-flex ">
          <span>
            Step4、
          </span>
          <span>分隊完成後，每位玩家選擇想扮演的角色，接著開始遊戲</span>
        </div>
        <div class="d-flex ">
          <span>
            Step5、
          </span>
          <span>
            在地圖上選擇任何一個就近的關卡進行闖關
          </span>
        </div>
      </div>
    </div>

    <div class="shadow-sm rounded p-3 my-4" id="notice">
      <h1 class="h5 my-2">
        <div class="text-center my-2 pb-2">注意事項</div>
      </h1>
      <div class="container">
        <ol class="pl-1">
          <li>
            所有活動將在手機上進行，不會有任何實體道具，<span class="text-danger">每位參與者都必須購買一個序號</span
            >，否則將無法參與活動喔。
          </li>
          <li>
            遊戲票券序號一經啟用，遊玩時限24小時，一旦過了時限將無法進行遊戲；因此，請玩家們務必<span
              class="text-danger"
              >當天遊玩前再登錄、啟用序號</span
            >唷。
          </li>
          <li>
            遊戲至少須2人才能進行，1個人無法進行遊戲，出發前請確認同行人數。
          </li>
          <li>
            遊戲中途有一次暫停時間的機會，暫停最長可至3hr，可利用時間休息、吃飯等等。
          </li>
          <li>
            隊伍出發前，如果臨時想增加玩家人數，請點選下方「購票頁面」按鈕，可以即時加購遊戲票券。
            <span class="text-danger">隊伍出發後，就不能再加入玩家了</span>。
          </li>
          <li>
            <span class="text-danger">嚴禁中離</span>
            ！進入遊戲後無法中途退出，隊上若有玩家中途離開遊戲，恐導致剩餘玩家遊戲無法繼續，因此遊戲開始了還請玩到最後。
          </li>
        </ol>
      </div>
      <div class="pb-2">
        <a href="https://www.badideasstudio.com/theyears" target="_blank" class="btn btn-primary btn-block">購票頁面</a>
      </div>
    </div>

    <div class="shadow-sm rounded p-3 my-4" id="install">
      <h1 class="h5 text-center my-2">
        <div class="my-2 pb-2" id="addGame">安裝遊戲</div>
      </h1>
      <div class="">
        <p>
          由於《那些年，我們一起的戀愛滋味》採用了新型態的PWA（Progressive Web
          App）技術進行開發，讓玩家無須下載APP也能享有APP般的最佳化介面體驗！
          因此為保持遊玩體驗順暢，在出發之前請協助每位同學在手機上安裝遊戲，
          <span class="text-danger">遊戲遊玩結束前，請勿自行刪除桌面遊戲圖示，以免造成遊戲資料缺失</span>。
        </p>
        <div class="mb-2"><strong>Android</strong></div>
        <div class="d-flex">
          <span>
            Step1、
          </span>
          <span>
            請使用Chrome網頁瀏覽器打開本頁面
            <img class="icon-sm ml-2 my-2" src="/img/icons/google_Chrome_icon.svg" alt="" />
          </span>
        </div>
        <div class="d-flex ">
          <span>
            Step2、
          </span>
          <span>
            若是在Line裡打開本頁面，請先點選Line視窗右上角的
            <img class="icon-sm ml-2 my-2" src="/img/icons/android-more.svg" alt="" /><br />
            之後選擇「以其他應用程式開啟」以改用Chrome開啟；若是在 Messenger裡打開本頁面，請先點選Messenger視窗右上角的
            <img class="icon-sm ml-2 my-2" src="/img/icons/android-more.svg" alt="" /><br />
            之後選擇「在Chrome開啟」以改用Chrome開啟
          </span>
        </div>
        <div class="d-flex ">
          <span>
            Step3、
          </span>
          <span>
            請點選Chrome視窗右上角的按鍵
            <img class="icon-sm ml-2 my-2" src="/img/icons/android-more.svg" alt="" /><br />
            之後選擇「加入主畫面」（PIXEL請選擇安裝「那些年，我們一起的戀愛滋味」）即可完成安裝
          </span>
        </div>
        <div class="d-flex mb-2">
          <span>
            Step4、
          </span>
          <span>
            遊戲當天直接點擊桌面上的遊戲新捷徑，即可直接啟動遊戲。
            <img class="icon-sm ml-2" src="/img/icons/apple-touch-icon-60x60.png" />
          </span>
        </div>
        <div class="d-flex mb-2">
          <span>
            Step5、
          </span>
          <span>點擊圖示後出現的遊戲起始頁面 </span>
        </div>
        <div><img class="mx-auto my-3 d-block" src="/img/init.png" alt="" style="width:50%;" /></div>
        <div class="mt-5 mb-2"><strong>iOS</strong></div>
        <div class="d-flex">
          <span>
            Step1、
          </span>
          <span>
            請使用Safari網頁瀏覽器打開本頁面
            <img class="icon-sm ml-2 my-2" src="/img/icons/safari_browser_logo.svg" alt="" />
          </span>
        </div>
        <div class="d-flex ">
          <span>
            Step2、
          </span>
          <span>
            若是在Line裡打開本頁面，請先點選Line視窗右上角的
            <img class="icon-sm ml-2 my-2" src="/img/icons/ios-share.svg" alt="" /><br />
            之後選擇「使用預設瀏覽器開啟」以改用Safari開啟；若是在Messenger裡打開本頁面，請先點選Messenger視窗右下角的
            <img class="icon-sm ml-2 my-2" src="/img/icons/ios-share.svg" alt="" /><br />
            之後選擇「在Safari開啟」以改用Safari開啟
          </span>
        </div>
        <div class="d-flex ">
          <span>
            Step3、
          </span>
          <span>
            請點選Safari視窗正下方按鍵
            <img class="icon-sm ml-2 my-2" src="/img/icons/ios-share.svg" alt="" /><br />
            之後選擇「加入主畫面」即可完成安裝
          </span>
        </div>
        <div class="d-flex mb-2">
          <span>
            Step4、
          </span>
          <span>
            遊戲當天直接點擊桌面上的遊戲新捷徑，即可直接啟動遊戲。
            <img class="icon-sm ml-2" src="/img/icons/apple-touch-icon-60x60.png" />
          </span>
        </div>
        <div class="d-flex mb-2">
          <span>
            Step5、
          </span>
          <span
            >蘋果的iOS調整了隱私設定，請在啟動遊戲前，先點選iPhone上的〔設定〕
            <img class="icon-sm ml-2" src="/img/setting.png" /><br />
            ，進入後選擇〔隱私權〕
            <img class="icon-sm ml-2" src="/img/privacy.png" /><br />
            ，再選擇〔定位服務〕
            <img class="icon-sm ml-2" src="/img/location.png" /><br />
            ，往下滑到〔Safari網站〕，選擇〔使用App期間〕
            <img class="icon-sm ml-2" src="/img/Safari.png" />
          </span>
        </div>
        <div class="d-flex mb-2">
          <span>
            Step6、
          </span>
          <span>點擊圖示後出現的遊戲起始頁面 </span>
        </div>
        <div><img class="mx-auto my-3 d-block" src="/img/init.png" alt="" style="width:50%;" /></div>
      </div>
    </div>
    <div>
      謝謝你們看到最後，希望你們都有好好看完😉
    </div>
    <div>歡迎一起來玩<b>《那些年，我們一起的戀愛滋味》</b>，壞主意工作室預祝你們玩得愉快！</div>
    <button class="btn btn-primary my-4 btn-block customService" @click="customService">
      聯絡客服（10:00-20:00）
      <img class="icon" src="/img/icons/line.svg" alt="" />
    </button>
  </div>
</template>
<script>
export default {
  name: "mobileTest",
  data() {
    return {
      screen: "",
      gps: "",
      DeviceMotionEvent: "",
      DeviceOrientationEvent: "",
      goTop: false,
      isCopy: false
    };
  },
  computed: {},
  mounted() {
    window.addEventListener("scroll", this.Onscroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.Onscroll);
  },
  methods: {
    Onscroll() {
      this.goTop = window.pageYOffset !== 0 ? true : false;
      if (!this.isCopy) {
        return;
      }
      this.isCopy = false;
    },
    checkDeviceOrientation() {
      if (typeof DeviceOrientationEvent.requestPermission === "function") {
        DeviceOrientationEvent.requestPermission()
          .then(permissionState => {
            if (permissionState == "granted") {
              this.DeviceMotionEvent = "最佳體驗";
            } else {
              this.DeviceMotionEvent = "不支援";
            }
          })
          .catch(() => {
            this.DeviceOrientationEvent = "不支援";
          });
      }
      if (window.DeviceOrientationEvent) this.DeviceOrientationEvent = "最佳體驗";
      else {
        this.DeviceOrientationEvent = "不支援";
      }
    },
    getDeviceMotionEvent() {
      if (typeof DeviceMotionEvent.requestPermission === "function") {
        DeviceMotionEvent.requestPermission()
          .then(permissionState => {
            if (permissionState == "granted") {
              this.DeviceMotionEvent = "最佳體驗";
            } else {
              this.DeviceMotionEvent = "不支援";
            }
          })
          .catch(() => {
            this.DeviceMotionEvent = "不支援";
          });
      }
      if (window.DeviceOrientationEvent) this.DeviceMotionEvent = "最佳體驗";
      else {
        this.DeviceMotionEvent = "不支援";
      }
    },
    doTest() {
      let windowWidth = (this.windowHeight = window.innerHeight);
      // if (windowWidth < 520) {
      if (windowWidth < 600) {
        //排除Iphone5&&SE
        this.screen = "體驗稍差";
      } else {
        this.screen = "最佳體驗";
      }

      if (navigator.geolocation) {
        this.gps = "最佳體驗";
      } else {
        this.gps = "不支援";
      }
      this.getDeviceMotionEvent();
      this.checkDeviceOrientation();
    },
    setStatus(words) {
      if (words == "體驗稍差") {
        return "text-warning";
      }
      if (words == "最佳體驗") {
        return "text-success";
      }
      if (words == "不支援") {
        return "text-danger";
      }
    },
    async copyLink() {
      await navigator.clipboard.writeText(location.href);
      this.isCopy = true;
    },
    customService() {
      // window.open("https://line.me/R/oaMessage/@489zbckq?message");
      window.open("https://lin.ee/WYQgbHE");
    }
  }
};
</script>

<style scoped lang="scss">
.moveTop {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 20%;
  right: 24px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  .icon-up-size {
    width: 24px;
    height: 24px;
    img {
      width: 100%;
    }
  }
}
.leadToInfo {
  a {
    color: #1ba7e9;
    line-height: 20px;
    vertical-align: bottom;
    margin: 4px 0;
    display: flex;
    align-items: flex-end;
  }
  svg {
    margin-right: 8px;
  }
}
.main-title {
  color: #1ba7e9;
}
.icon-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.share-icon {
  width: 54px;
  height: 54px;
}
.icon-sm {
  width: 24px;
  height: 24px;
}
.before-play {
  width: 100%;
}
.copyDiv {
  background-color: #333;
  color: #fff;
  width: 100px;
  padding: 12px 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
  z-index: 99;
}
.customService {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #1ba7e9;
  img {
    width: 24px;
    height: 24px;
  }
}
</style>
