<template>
  <div class="position-relative dark-bg">
    <div class="info-content">
      <div class="d-flex">
        <a @click="$router.replace('homeMap')">
          <svg class="btn-icon mr-3">
            <use xlink:href="#icon-back" class="fill-primary"></use>
          </svg>
        </a>
        <h4 class="text-white">Q&A</h4>
      </div>
      <div class="text-center small-font text-white mt-2 mb-3">
        <div class="search-input w-100 d-flex align-content-center position-relative flex-wrap">
          <img class="position-absolute icon-search" src="@/assets/images/icon-search.svg" alt="" />
          <input type="text" placeholder="請描述您的問題" v-model="searchContent" />
        </div>
      </div>

      <ul class="qa-list bg-white list-unstyled m-0">
        <li
          class=""
          :class="{ active: index == selectQA }"
          v-for="(qType, index) in qaList"
          :key="index"
          @click="selectType(index)"
        >
          <div class="qa-type">{{ qType.name }}</div>
          <div class="qa-list-item">
            <ul class="list-unstyled m-0">
              <li class="pb-2" v-for="item in qType.items" :key="item.q">
                <h6 class="mb-1">{{ item.q }}</h6>
                <div>{{ item.a }}</div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "qa",
  data() {
    return {
      searchContent: "",
      selectQA: 0,
      qaList: [
        {
          name: "組隊問題",
          items: [
            {
              q: "問題1",
              a: "解答1"
            },
            {
              q: "問題2",
              a: "解答2"
            }
          ]
        },
        {
          name: "系統問題",
          items: [
            {
              q: "問題1",
              a: "解答1"
            },
            {
              q: "問題2",
              a: "解答2"
            }
          ]
        },
        {
          name: "遊戲問題",
          items: [
            {
              q: "問題1",
              a: "解答1"
            },
            {
              q: "問題2",
              a: "解答2"
            }
          ]
        }
      ]
    };
  },
  methods: {
    selectType: function(index) {
      this.selectQA = index;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/__var";

.dark-bg {
  min-height: 100vh;
}

.qa-list {
  > li {
    .qa-type {
      padding: 7px 20px;
      border: 1px solid #dedede;
    }
    .qa-list-item {
      max-height: 0;
      transition: 0.5s;
      overflow: hidden;
    }
    &.active {
      .qa-type {
        @include default-shadow;
      }
      .qa-list-item {
        max-height: 500px;
      }
      ul {
      }
    }
    ul {
      /*transition: max-height .5s, padding .3s;*/
      padding: 20px 20px 10px;
    }
  }
}
</style>
