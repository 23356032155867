<template>
  <div class="position-relative">
    <div class="fighting-mobile position-fixed">
      <img src="/img/mobile-hand.png" alt="" />
    </div>
  </div>
</template>

<script>
import CharacterFromVuex from "../Mixins/CharacterFromVuex";
import UserFromVuex from "../Mixins/UserFromVuex";
import TeamFromVuex from "@/Mixins/TeamFromVuex";

export default {
  name: "About",
  mixins: [CharacterFromVuex, UserFromVuex,TeamFromVuex],
  components: {},
  mounted: function() {},
  data() {
    return {};
  },
  methods: {},
  beforeRouteEnter(to, from, next){
    next(vm => {
      if(vm?.team){
        vm.router.replace('homeMap')
        return
      }
    })
  }
};
</script>
<style scoped></style>
