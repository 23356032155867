export default {
  methods: {
    showAvatar: function(pic = "/img/profile.svg") {
      if (pic.includes("http")) {
        return pic;
      }
      if (pic.includes("profile")) {
        return pic;
      }
      if (pic) {
        return "/img/" + pic;
      }
      return "/img/default-avatar.png";
    },
    getAvatar(row) {
      let avatar = row?.character?.user?.avatar ?? row?.user?.avatar ?? "";
      if (avatar?.includes("default") && row?.character?.picture) {
        avatar = row?.character?.picture;
      }
      if (avatar?.includes("profile") && row?.character?.picture) {
        avatar = row?.character?.picture;
      }
      return this.showAvatar(avatar || row?.character?.picture || row?.picture || row?.avatar);
    }
  }
};
