<template>
  <div class="full-page overflow-hidden position-relative">
    <div class="full-panel game-intro">
      <h1 v-if="firstPage" class="full-page-title-s text-center">重要注意事項</h1>
      <h1 v-else class="full-page-title-s text-center">那些年玩什麼</h1>
    </div>
    <rule :rules="rules" @endPage="changeBtnText" @changePage="isFirstPage"></rule>
    <a v-show="showBtn" @click="$router.replace('consent')" class="btn-border position-absolute skip-btn text-white"
      >繼續</a
    >
  </div>
</template>

<script>
import rule from "@/views/part/rule";
import {mapGetters} from "vuex";

export default {
  name: "gameIntro",
  computed:{
    ...mapGetters('Team',['team_payload'])
  },
  components: {
    rule
  },
  mounted () {
    if(this.team_payload?.router){
      this.$router.replace(this.team_payload.router)
    }
  },
  data() {
    return {
      firstPage: true,
      showBtn: false,
      rules: [
        {
          component: "gameIntroVideo"
        },
        {
          title: "",
          content:
            "<img src='/img/game-intro1.jpg'><p class='pt-3'>未來的我們，你們好呀！</p><p>今天，你們將重溫我們高中時期在這座城市留下的眾多美好回憶</p><p>我們在高中認識了彼此，參與了彼此的青春</p><p>當然，青春裡還有不能缺少的酸酸甜甜愛情滋味</p>"
        },
        {
          title: "",
          content:
            "<img src='/img/game-intro2.jpg'><p class='pt-3'>我們都喜歡那個善解人意、開朗活潑的女孩——小桃，有她在的每一天，都是最晴朗的日子！每個人都把握任何一個能表現的時機場合，爭先恐後、不擇手段地搏取小桃的好感</p>"
        },
        {
          title: "",
          content:
            "<img src='/img/game-intro3.jpg'><p class='pt-3'>過程之中，你們可能會需要活動筋骨、甚至追趕跑跳，屆時還請務必留意自身安全、注意左右來車！無論再怎麼激動，也要注意安全，而且千萬不要摔到手機啊！</p>"
        },
        {
          title: "",
          content:
            "<img src='/img/game-intro4.jpg'><p class='pt-3'>另外記住，一定要明察秋毫，絕對不能放過路上任何蛛絲馬跡，一草一木都是構築青春回憶的基石</p>"
        },
        {
          title: "",
          content:
            "<img src='/img/game-intro5.jpeg'><p class='pt-3 text-center'><strong>玩法說明</strong></p><p class='mb-0 text-center'><strong>勝利條件</strong></p><p>完成關卡裡的任務以取得好感值，好感值最高的玩家最終將贏得小桃芳心！</p><p class='mb-0 text-center'><strong>主線關卡</strong></p><p>進入主地圖後可任意選擇一個關卡，並由該關卡的代表角色述說他的高中回憶，破完一主線關卡才可再選擇下一個主線關卡</p>"
        },
        {
          title: "",
          content:
            "<img src='/img/game-intro6.jpeg'><p class='pt-3 text-center'><strong>主線關卡中的拍照任務</strong></p><p>每個拍照任務都有建議的團體拍照地點，可以依循地標前往拍照，也可以直接在任意一處拍團照上傳照片，照片預設正方形大小，請務必使用正方形格式上傳，避免被裁切！</p><p>在遊戲最後，你們的青春回憶會全部被蒐集起來的！</p>"
        },
        {
          title: "",
          content:
            "<img src='/img/game-intro7.jpeg'><p class='pt-3 text-center'><strong>支線</strong></p><p>進入每個主線關卡的區域以後，會自動解鎖該區域的支線——美食任務</p>"
        },
        {
          title: "",
          content:
            "<img src='/img/game-intro8.jpeg'><p class='pt-3 text-center'><strong>支線 美食任務</strong></p><p>為了追女神小桃，連她愛吃的美食也得摸透透啦！</p><p>每個店家都有小桃關於美食的回憶，了解喜歡的人當然也會增加好感度啦！美食任務的謎題不需要到店家位置也可以進行解謎，不進行也不會影響主線，可視時間斟酌遊玩，只有最先解開謎題者會增加好感度</p>"
        },
        {
          title: "",
          content:
            "<img src='/img/game-intro9.png'><p class='pt-3 text-center'><strong>行前檢查</strong></p><p>由於遊戲過程須取得手機硬體的權限，在看到詢問授權時，請務必選擇『允許』，否則會無法正常進行遊戲。若遊玩過程中，硬體授權視窗不慎按到『取消』，請先強制關閉運行中的遊戲APP，再點擊手機桌面的遊戲APP圖示重啟遊戲，在詢問硬體授權時選擇『允許』後即可正常遊戲</p>"
        },
        {
          title: "",
          content:
            "<img src='/img/game-intro10.png'><p class='pt-3'>最後，請帶著愉悅的心情、準備重回高中的那些年，你們肯定能有個無比精采的一天！出發！</p>"
        }
      ]
    };
  },
  methods: {
    changeBtnText(endPage) {
      if (endPage) {
        this.showBtn = true;
      } else {
        this.showBtn = false;
      }
    },
    isFirstPage(index) {
      this.firstPage = index === 0 ? true : false;
    }
  }
};
</script>

<style scoped lang="scss">
video {
  width: 275px;
  height: 275px;
}
.skip-btn {
  bottom: 20px;
  right: 30px;
  position: absolute;
  top: auto;
}
</style>
