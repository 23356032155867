import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("Team", ["team", "members", "team_payload", "character_map"]),
    ...mapGetters("Score", ["score_map", "hintCount"]),
    isLeader() {
      return this.team?.payload?.leader == this?.user?.id;
    },
    allMemberOnline() {
      let members = this.members.map(d => {
        let isConnect = this?.team_payload?.user_connect_map?.[d.user_id];
        d.isConnect = isConnect;
        return d;
      });
      return !members.find(d => d.isConnect == false);
    }
  },
  watch: {
    isOnline(val) {
      if (val === false) {
        this.setOnline();
      }
    }
  },
  methods: {
    ...mapActions("Team", [
      "addMember",
      "removeMember",
      "setTeam",
      "teamJoin",
      "teamSync",
      "setUserScore",
      "hintMapAdd"
    ]),
    ...mapActions("User", ["setOnline"]),
    ...mapActions("Score", ["AddUserScore", "hintMapAdd", "addScoreByRate"]),
    addHint(key) {
      this.hintMapAdd(key);
    },
    addScore: function(score) {
      this.setUserScore({
        score: score
      });
    }
  }
};
