<template>
  <div class="position-relative app-content">
    <div class="full-panel game-intro">
      <h1 class="full-page-title-s text-center mb-3">服務條款</h1>
      <h3 class="h5">1. 認知與接受條款</h3>
      <p>
        感謝您使用壞主意互動娛樂股份有限公司（以下簡稱「壞主意」）提供的「那些年，我們一起的戀愛滋味」互動娛樂體驗（以下簡稱「本服務」）。當您開始使用本服務之時，即表示您已閱讀、瞭解並同意接受本服務條款之所有內容，並完全接受本服務現有與未來衍生的服務項目及內容。壞主意有權利於任何時間修改、調整、變更本服務條款之內容，修改後的服務條款內容將公佈於網站上，壞主意將不會個別通知，建議使用者隨時注意服務條款的修改或變更。使用者於任何修改、調整或變更後繼續使用本服務時，視為使用者已閱讀、瞭解並同意接受該等修改或變更。如您是法律上之無行為能力人或限制行為能力人，請將本服務所有服務條款交由您的法定代理人（如父母、輔助人或監護人）閱讀；若不同意上述的服務條款修訂或更新方式，或不接受本服務條款的其他任一約定，使用者應立即停止使用本服務。
      </p>
      <h3 class="h5">2. 法律責任聲明</h3>
      <p>
        本服務進行時所需之一切行動皆為正常合法行為，當您在體驗本服務時，請注意周圍環境，並且務必注意自身安全，同時也請遵守所在場地之原有規範。當您開始體驗本服務時，視為同意自行承擔使用本服務的風險。若使用者在體驗過程中有任何違法、違規行為，或造成自身、他人利益之損害，皆由使用者自行負責。本服務所使用之素材，包含但不限於圖片、影片、聲音、文字、網頁源程式碼等，皆為壞主意製作或合法取得，受到智慧財產權保障，對於任何未經許可的盜用、盜錄等侵權行為，壞主意將保留法律追訴之權利。本服務條款所訂之條文全部或部份無效時，不影響其他有效條文之效力。使用者因使用本服務而生之爭議，如有訴訟之必要時，同意以台灣台北地方法院為第一審管轄法院。
      </p>
      <p>
        若您未遵守本服務條款，抑或我們懷疑您對於本服務有作弊、詐欺或濫用，抑或我們懷疑您帳戶有其他非法活動，我們得以隨時暫停或終止您使用本服務的權利而不另行通知。在任何本服務或您的帳戶終止後，本條款的法律責任聲明將繼續有效。
      </p>
      <h3 class="h5">3.服務安全注意項目</h3>
      <p>
        本服務進行時，需視情況揮動、展示以及瀏覽手機，在操作過程中須自行留心維護個人手機硬體及自身財物的安全，如因不安全操作或行為導致手機或財物遺失、摔落或是任何其他形式之損壞，由使用者自行負責。體驗過程中會需要在不同地點、場景間移動，移動時請務必遵守交通規範，注意自身安全，並且避免邊走邊使用手機的行為。本服務禁止在馬路上進行任何體驗之相關行為，一切體驗須在使用者確認自身安全無虞之下方可繼續進行。
      </p>
      <h3 class="h5">4.退款與突發狀況</h3>
      <p>本服務啟動序號一旦輸入、驗證後，視同於正式啟用服務，基於智慧財產權保護原則，除以下狀況否則不予退費：</p>
      <ol>
        <li>「那些年，我們一起的戀愛滋味」互動娛樂體驗之頁面或雲端伺服器資料因故無正常運作，導致體驗中斷或無法進行</li>
        <li>其他不可抗力之因素（如：戰爭、天然或人為災害等）</li>
      </ol>
      <p>
        若體驗中的地點或場景因改建或變更或臨時關閉，壞主意將統一於網站上公告而不個別通知。使用者開始進行服務前，需要自行參酌當時的天氣狀況與官方公告。
      </p>
      <button @click="$router.replace('enterSn')" class="btn btn-primary btn-block">我同意</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "consent"
};
</script>

<style scoped></style>
