import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("User", ["user", "team_member", "ticket", "token", "character"])
  },
  methods: {
    ...mapActions("User", ["setUser", "loginExpire", "logout", "SyncUserStatus", "setUserHint", "setOnline"])
  },
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     // if (vm.loginExpire()) {
  //     //   vm.logout();
  //     //   // vm.$router.replace('login');
  //     // }
  //   });
  // }
};
