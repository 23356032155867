import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("Character", ["roles", "getRoleByName"])
  }
  // methods: {
  //   ...mapActions("User", ["setUser"])
  // }
};
