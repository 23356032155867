<template>
  <div class="full-page overflow-hidden position-relative app-content">
    <div v-if="lock" class="loader loader-default is-active" data-text data-blink> </div>
    <div class="full-panel d-flex flex-column justify-content-between h-100">
      <div>
        <h1 class="full-page-title">輸入序號</h1>
        <div class="form-group position-relative">
          <input
            id="enterSn"
            type="text"
            class="login-input-shadow"
            placeholder="請遊戲當天再輸入序號"
            v-model="code"
          />
        </div>
        <div class="d-flex align-items-center">
          <div class="mr-2">沒有序號嗎?</div>
          <a href="https://www.badideasstudio.com/theyears" target="_blank" class="btn-border">前往售票頁面</a>
        </div>
      </div>
      <div>
        <!-- <div class="pb-3 suggest">請<a class="default-link">遊戲當天</a>再輸入序號</div> -->
        <a id="teamStart" @click="doOpenTicket" class="btn btn-primary btn-block text-white">開始遊戲</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalMixin from "../Mixins/ModalMixin";
import UserFromVuex from "../Mixins/UserFromVuex";
import WebSocketMixin from "../Mixins/WebSocketMixin";

export default {
  name: "enterSn",
  mixins: [ModalMixin, UserFromVuex, WebSocketMixin],
  data() {
    return {
      lock:false,
      code: ""
    };
  },
  computed: {
    ...mapGetters("User", ["ticket"]),
    ...mapGetters("Game", ["game"])
  },
  mounted() {
    // If the game is still playing, then push route to continue it.
    if (!this.game?.is_end && this.ticket?.id) {
      this.$router.replace("teamCreate");
    }
  },
  methods: {
    ...mapActions("User", ["openTicket"]),
    async doOpenTicket() {

      if (this.code.length < 8) {
        this.setModal("序號錯誤請重新輸入");
        return;
      }
      this.lock = true;
      try {
        let data = await this.openTicket(this.code);
        if (data?.status == "fail") {
          this.setModal(data?.message);
          this.lock = false;
          return;
        }
        if (data?.status == "success") {
          await this.$router.replace({ name: "teamStart" });

        }
      } catch (e) {
        console.log(e);
        this.lock = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import '../assets/css/loader-default.css';

.suggest {
  font-size: 24px;
}

.login-input-shadow {
  border: none;
  background: none;
  padding: 10px 30px;
  width: 100%;
  border-radius: 30px;
  transition: 0.3s;
  -webkit-appearance: none;
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16);
}
</style>
